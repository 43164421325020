import React from "react";
import Layout from "../components/common/Layout";
import HeroSection from "../components/common/HeroSection";
import willowSquareImg from "../images/commercial/completed/willow-square.jpg";
import willowSquareImg2 from "../images/commercial/completed/willow-square3.jpg";

function CompletedProjectsPage() {
  return (
    <Layout>
      <HeroSection
        title="Commercial Completed Projects"
        backgroundImage={
          // projects[0] ? projects[0].node.banner.childImageSharp.fluid.src : null
          willowSquareImg
        }
      />
      <section className="section-pad border-bottom">
        <div className="container">
          <div className="row justify-content-center pb-5">
            {/* {projects.map(({ node }) => (
              <div className="col-md-6 col-xl-4 mb-3" key={node.id}>
                <ProjectCard {...node} />
              </div>
            ))} */}

            <div className="col-md-6 col-xl-4 mb-3">
              <a
                className="button-as-link project-card"
                href={"/willow-square/"}
              >
                <div className="relative">
                  <div
                    className="project-card-img gatsby-image-wrapper"
                    style={{ position: "relative", overflow: "hidden" }}
                  >
                    <div
                      aria-hidden="true"
                      style={{ width: "100%", paddingBottom: "50.5%" }}
                    ></div>
                    <img
                      src={willowSquareImg2}
                      alt={"title"}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center center",
                        opacity: 1,
                      }}
                    />
                  </div>
                </div>
                <div className="project-card-body">
                  <h5 className="project-card-title">{"Willow Square"}</h5>
                </div>
                <div className="project-card-footer">
                  {"A vibrant workspace creates a productive environment."}
                </div>
              </a>
            </div>
          </div>

          {/* <div className="row pt-5 border-top">
            <div className="col-lg-1" />
            <div className="col-lg-10">
              <h4 className="mb-4 text-center">
                Other Residential Completed Projects
              </h4>
              <OthersTable />
            </div>
          </div> */}
        </div>
      </section>
    </Layout>
  );
}

// const OthersTable = () => {
//   return (
//     <table className="table table-bordered">
//       <thead>
//         <tr>
//           <th>Sl No.</th>
//           <th>Project</th>
//           <th>Site Address</th>
//         </tr>
//       </thead>
//       <tbody>
//         <tr>
//           <td>1</td>
//           <td>Baashyaam Navarathna</td>
//           <td>Thiruneermalai Main Road, Chrompet, Chennai — 600044</td>
//         </tr>
//         <tr>
//           <td>2</td>
//           <td>jasmine Court</td>
//           <td>Kattupakkam, Chennai</td>
//         </tr>
//         <tr>
//           <td>3</td>
//           <td>Nagamuni Nest</td>
//           <td>No.29 & 31 Thanikachalam Road, T.Nagar, Chennai - 600 017</td>
//         </tr>
//         <tr>
//           <td>4</td>
//           <td>Rajalakshmi</td>
//           <td>No.8/21, Crescent Park Street, T.Nagar, Chennai- 600 017</td>
//         </tr>
//         <tr>
//           <td>5</td>
//           <td>Dharma Enclave</td>
//           <td>No.7, West Road, West CIT Nagar, Chennai - 600 035</td>
//         </tr>
//         <tr>
//           <td>6</td>
//           <td>Devi Ambuja</td>
//           <td>No.24-25, 2nd Cross Street, West CIT Nagar, Chennai - 600 035</td>
//         </tr>
//         <tr>
//           <td>7</td>
//           <td>Baashyaam Castle</td>
//           <td>No.127, Abhiramapuram 4th Street, Chennai - 600 028</td>
//         </tr>
//         <tr>
//           <td>8</td>
//           <td>13 10 Baashyaam Manor</td>
//           <td>No.18, Warren Road, Mylapore, Chennai - 600 004</td>
//         </tr>
//         <tr>
//           <td>9</td>
//           <td>Baashyaam Enclave</td>
//           <td>No.47, 4th Main Road, Gandhi Nagar, Adyar, Chennai - 600 020</td>
//         </tr>
//         <tr>
//           <td>10</td>
//           <td>V. Subramaniyam</td>
//           <td>No.19, Justice Sundaram Road, Mylapore, Chennai - 600 004</td>
//         </tr>
//         <tr>
//           <td>11</td>
//           <td>Neelakandan Enclave</td>
//           <td>
//             No.12, Ananthapuram, Dr. Ranga Road, Mylapore, Chennai - 600 004
//           </td>
//         </tr>
//         <tr>
//           <td>12</td>
//           <td>Radhakrishna</td>
//           <td>No.8, Gopalakrishnan Street, T.Nagar, Chennai - 600 017</td>
//         </tr>
//         <tr>
//           <td>13</td>
//           <td>Banu</td>
//           <td>
//             Old No.9, New No.23, 2nd Cross Street, West CIT Nagar, Chennai - 600
//             035
//           </td>
//         </tr>
//         <tr>
//           <td>14</td>
//           <td>Nandavrajam</td>
//           <td>
//             Old Door No.22, New Door No.40, Lloyds Road First Street,
//             Royapettah, Chennai-600 014.
//           </td>
//         </tr>
//       </tbody>
//     </table>
//   );
// };

export default CompletedProjectsPage;
